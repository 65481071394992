import React, { useEffect, useState } from "react";
import {
  DivItemStockEntry,
  FormItemStockEntry,
  DivOrgItemStockEntry,
  LabelItemStockEntry,
  InputSmall,
  LabelCalcResult,
  SelectOption,
  Options,
  SubmitFormItemStockEntry,
  DivOrgInput,
  DivOrgScreen,
  TitleDelivery,
  SelectProvider,
  SelectProduct,
} from "./NewItemStyle";
import TableItem from "../../Tables/TableItem/TableItem";

import { useDebounce } from "use-debounce";

import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export default function NewItem(props) {
  const listProviders = props.providersData.provider;
  const listProducts = props.productsInfo.product;

  const [priceUnitFormated, setPriceUnitFormated] = useState("");
  const [productStock, setProductStock] = useState([]);
  const [productId, setProductId] = useState(0);
  const [productInfo, setProductInfo] = useState({});
  const [productSearch] = useDebounce(productId, 300);
  const [valueDelivery, setValueDelivery] = useState(0);
  const [infoQtd, setInfoQtd] = useState("");
  const [batchOption, setBatchOption] = useState();

  // const [inputData, setInputData] = useState("");
  const [inputDelivery, setInputDelivery] = useState(0);
  const [infoOrderStock, setInfoOrderStock] = useState([]);

  const [isClearable] = useState(true);
  const [provider, setProvider] = useState([]);
  const [optionsProviders, setOptionsProviders] = useState([]);

  const [product, setProduct] = useState([]);
  const [optionsProducts, setOptionsProducts] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState
  } = useForm({
    defaultValues: {
      priceTotal: 0,
    },
  });

  // Aqui faz o envio de um ojbeto e retorna o valor inicial
  function sendProductToStock(dataProduct) {
    let errors = [];

    // Validação do preço unitário
    const priceUnit = Number(priceUnitFormated);
    if (isNaN(priceUnit) || priceUnit <= 0) {
      errors.push("O preço unitário deve ser maior que 0.");
    } else {
      dataProduct.priceUnit = priceUnit;
    }

    // Validação do produto
    if (!product.value) {
      errors.push("Favor selecionar um Produto.");
    } else {
      dataProduct.nameProduct = product.label;
      dataProduct.idProduct = product.value;
    }

    // Validação do fornecedor
    if (!provider.value) {
      errors.push("Favor selecionar um Fornecedor.");
    } else {
      dataProduct.idProvider = provider.value;
    }

    // Validação da quantidade
    const quantity = Number(infoQtd);
    if (isNaN(quantity) || quantity <= 0) {
      errors.push("Favor colocar uma quantidade maior que 0.");
    } else {
      dataProduct.qtdItems = quantity;
    }

    // Se houver erros, exibe os alertas e interrompe a execução
    if (errors.length > 0) {
      window.alert(errors.join("\n"));
      return;
    }

    // Definição do valor do frete
    dataProduct.valueDelivery = Number(valueDelivery) || 0;

    setInfoOrderStock({
      statusDelivery: dataProduct.statusDelivery,
      valueDelivery: Number(valueDelivery),
      idProvider: provider.value,
      idEntryStock: 0
    });

    delete dataProduct.statusDelivery
    setProductStock(dataProduct);
  }

  const qtdItems = Number(infoQtd);
  const priceUnit = Number(priceUnitFormated);

  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  const sendProvider = (provider) => {
    setProvider(provider);
  };
  const sendProduct = (product) => {
    setProduct(product);
  };

  useEffect(() => {
    let totalPrice;

    if (qtdItems > 0) {
      totalPrice = qtdItems * priceUnit;
      if (totalPrice) {
        setValue("priceTotal", Number(totalPrice));
      } else {
        setValue("priceTotal", 0);
      }
    }
    // getProductInfo(productSearch)
  }, [qtdItems, priceUnit, setValue]);

  useEffect(() => {
    if (productSearch.length === 0) {
      setProductInfo(productSearch);
      setBatchOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if(formState.isSubmitted) {
      setTimeout(() => {
        // reset();
        setPriceUnitFormated("");
        setInfoQtd("");
        setBatchOption();
        setProductInfo([]);
        setProductId(0);
      }, 1500);
    }
  }, [formState.isSubmitted, reset]);


  useEffect(() => {
    if (listProviders?.length > 0) {
      const optionsProviders = listProviders.map((providers) => ({
        value: providers.idProvider,
        label: providers.nameProvider,
      }));
      setOptionsProviders(optionsProviders);
    }
    if (listProducts?.length > 0) {
      const optionsProducts = listProducts.map((product) => ({
        value: product.idProduct,
        label: product.nameProduct + " / " + product.nameBrand,
        priceSell: product.priceSell,
        codProd: product.codProd,
      }));
      setOptionsProducts(optionsProducts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProducts]);

  return (
    <DivOrgScreen>
      <DivItemStockEntry>
        <FormItemStockEntry onSubmit={handleSubmit(sendProductToStock)}>
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Nome do Produto</LabelItemStockEntry>
            <SelectProduct
              name="product"
              placeholder="Selecione"
              options={optionsProducts}
              isClearable={isClearable}
              onChange={sendProduct}
            />
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Quantidade</LabelItemStockEntry>
              <InputSmall
                // disabled={inputQtd}
                value={infoQtd}
                type="number"
                step="any"
                onChange={(e) => {
                  // limitQtd(e.target.value);
                  setInfoQtd(e.target.value);
                }}
                onFocus={disableScroll}
              />
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              {/* colocar para utilizar valores em decimal e formatar R$00.000.00*/}
              <LabelItemStockEntry>Preço Unitario</LabelItemStockEntry>
              <NumericFormat
                value={priceUnitFormated}
                defaultValue={0}
                customInput={InputSmall}
                placeholder="R$ "
                allowEmptyFormatting
                mask="_"
                decimalSeparator=","
                fixedDecimalScale
                thousandSeparator="."
                decimalScale={2}
                prefix={"R$"}
                onValueChange={(values, sourceInfo) => {
                  setPriceUnitFormated(Number(values.value));
                }}
              />
            </DivOrgInput>
            <DivOrgInput>
              <LabelItemStockEntry>Preço Total</LabelItemStockEntry>
              {/* Label do calc de preço unit x qtdItems e formatar para 
              var f = atual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});*/}
              {/* <LabelCalcResult>R$ {watch("priceTotal" || 0)}</LabelCalcResult> */}
              <NumericFormat
                customInput={LabelCalcResult}
                displayType="text"
                value={watch("priceTotal")}
                decimalSeparator=","
                thousandSeparator="."
                fixedDecimalScale
                decimalScale={2}
                prefix={"R$ "}
              />
            </DivOrgInput>
          </DivOrgItemStockEntry>
          <DivOrgItemStockEntry>
            <LabelItemStockEntry>Fornecedor</LabelItemStockEntry>
            <SelectProvider
              name="provider"
              placeholder="Selecione"
              options={optionsProviders}
              isClearable={isClearable}
              onChange={sendProvider}
            />
          </DivOrgItemStockEntry>
          <TitleDelivery>Valor do Frete</TitleDelivery>
          <DivOrgItemStockEntry>
            <DivOrgInput>
              <LabelItemStockEntry>Frete</LabelItemStockEntry>
              <SelectOption
                {...register("statusDelivery", {
                  onChange: (e) => {
                    setInputDelivery(e.target.value);
                  },
                })}
              >
                <Options value="">Selecionar</Options>
                <Options value="sim">Sim</Options>
                <Options value="nao">Não</Options>
              </SelectOption>
            </DivOrgInput>
            {inputDelivery === "sim" && (
              <DivOrgInput>
                <LabelItemStockEntry>Valor</LabelItemStockEntry>
                <NumericFormat
                  placeholder="R$ "
                  customInput={InputSmall}
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"R$"}
                  onValueChange={(values, sourceInfo) => {
                    setValueDelivery(Number(values.value));
                  }}
                />
              </DivOrgInput>
            )}
          </DivOrgItemStockEntry>
          <SubmitFormItemStockEntry type="submit">
            Adicionar
          </SubmitFormItemStockEntry>
        </FormItemStockEntry>
      </DivItemStockEntry>
      {/* Passa um objeto, resetar os inputs apos enviar */}
      <TableItem productStock={productStock} infoOrderStock={infoOrderStock} />
    </DivOrgScreen>
  );
}
