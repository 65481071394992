import React, { useEffect, useRef, useState } from "react";
import {
  BtnClose,
  BtnNewOrder,
  DivOrderAnimation,
  DivOrgBtnClose,
  DivOrgBtnOrder,
  // StatusOrder,
  TitleAnimation,
  // DivOrgLoading,
  BtnPrint,
  DivOrgInfoAnimation,
  DivInfoCompany,
  DivOrgTable,
  DivOrgTitle,
  InfoCompany,
  Line,
  Table,
  TableBody,
  TableFoot,
  TableHead,
  TablePayments,
  TdPayments,
  TdTable,
  TdTableDesc,
  TdTablePrices,
  TdValues,
  ThTable,
  ThTableDesc,
  ThTablePrices,
  TitlePrint,
  TrPayments,
  TrTable,
  DivOrgInfoCompany,
  DivInfoClient,
  // BtnMail,
  PricesFormat,
  DivOrgSelect,
  SelectPaper,
  OptionsPaper,
  DivDelivery,
  DivOrgTitleDelivery,
  TitleDelivery,
  InfoDelivery,
  DivOrgScreen,
  InfoDate,
  AlertChange,
} from "./FinishOrderAnimationStyle";
import { useNavigate } from "react-router-dom";
import { Close } from "@styled-icons/material";
// import { ClipLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import FormatDatesFront from "../../utils/formatDateFront.mjs";

export default function FinishOrderAnimation(props) {
  // const orderResponse = props.trueAnimation;
  const infoFinishOrder = props.infoFinishOrder.order;
  const infoClient = props.infoClientLocal;
  const infoEmployee = props.employee
  const FormatDate = new FormatDatesFront();

  // const orderResponse = true;
  const navigate = useNavigate();

  const [orderList, setOrderList] = useState([]);
  // const [showList, setShowList] = useState(false);
  const [typePaper, setTypePaper] = useState();
  const [showList, setShowList] = useState(false);
  const [dateChange, setDateChange] = useState()

  // const [finishOrder, setFinishOrder] = useState({ message: "pendente" });
  // const [infoPrint, setInfoPrint] = useState(false);

  const createListOrders = (dataClient) => {
    setShowList(true);
    if (showList) {
      setOrderList(dataClient);
    }
  };

  const printRef = useRef();
  const printOrder = useReactToPrint({
    content: () => printRef.current,

    onAfterPrint: () => {
      navigate("/comercial");
    },
  });

  const parseName = (oneName) => {
    if (oneName) {
      const firstName = oneName || "";

      var fullName = firstName.concat(" ");

      const formatName = fullName.split(" ");
      for (var i = 0; i < formatName.length; i++) {
        formatName[i] =
          formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
      }
      let result = formatName?.join(" ");

      return result;
    }
  };

  useEffect(() => {
    createListOrders(infoFinishOrder?.itemsCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFinishOrder?.itemsCart]);

  useEffect(() => {
    const dateCreated = infoFinishOrder?.dateCreated;
  
    if (dateCreated) {
      // Formata a data sem hora
      const formattedDate = new Date(FormatDate.formatDateNoHour(dateCreated));
  
      // Obtem os valores da data usando UTC
      const updatedDate = new Date(Date.UTC(
        formattedDate.getUTCFullYear(),
        formattedDate.getUTCMonth(),
        formattedDate.getUTCDate()
      ));
  
      // Adiciona 7 dias
      updatedDate.setUTCDate(updatedDate.getUTCDate() + 7);
  
      // Exibe a nova data
      setDateChange(FormatDate.formatDateInput(updatedDate.toISOString().slice(0, 10))); // Saída no formato YYYY-MM-DD
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoFinishOrder]);

  return (
    <DivOrderAnimation
      show={props.showAnimation}
      // show={true}
    >
      <TitleAnimation>Venda realizada com sucesso</TitleAnimation>
      <DivOrgScreen>
        <DivOrgInfoAnimation>
          <DivOrgTable ref={printRef} papersType={typePaper}>
            <DivOrgBtnClose>
              <BtnClose
                onClick={() => {
                  props.setShowAnimation(false);
                  props.setTrueAnimation(false);
                  navigate("/comercial");
                }}
              >
                <Close />
              </BtnClose>
            </DivOrgBtnClose>
            <DivOrgInfoCompany>
              <DivInfoCompany>
                <InfoCompany>
                  Nome da Empresa: {process.env.REACT_APP_NAME_EMPRESA}
                </InfoCompany>
                <InfoCompany>
                  CNPJ: {process.env.REACT_APP_CNPJ_EMPRESA}
                </InfoCompany>
                <InfoCompany>
                  Endereço: {process.env.REACT_APP_ENDERECO_EMPRESA}
                </InfoCompany>
                <InfoCompany>
                  Bairro: {process.env.REACT_APP_BAIRRO_EMPRESA}
                </InfoCompany>
                <InfoCompany>
                  Cidade: {process.env.REACT_APP_CIDADE_EMPRESA} /{" "}
                  {process.env.REACT_APP_ESTADO_EMPRESA}
                </InfoCompany>
                <InfoCompany>
                  Vendedor: {parseName(infoEmployee.label)}
                </InfoCompany>
              </DivInfoCompany>
              <DivInfoClient>
                <InfoCompany>Cliente: {infoClient.nameClient}</InfoCompany>
                <InfoCompany>Nº Pedido: {infoFinishOrder?.idOrder}</InfoCompany>
                <InfoDate>Data da compra: {infoFinishOrder?.dateCreated}</InfoDate>
                <InfoDate>Data de troca ate: {dateChange}</InfoDate>
              </DivInfoClient>
            </DivOrgInfoCompany>
            <DivOrgTitle>
              <TitlePrint>Nota Não fiscal</TitlePrint>
              <AlertChange>Trocas em ate 7 dias com essa nota</AlertChange>
            </DivOrgTitle>
            <Line />
            <Table>
              <TableHead>
                <TrTable>
                  <ThTable>Items</ThTable>
                  <ThTable>Cod.</ThTable>
                  <ThTableDesc>Desc.</ThTableDesc>
                  <ThTable>QTD</ThTable>
                  <ThTablePrices>Preço Unitario</ThTablePrices>
                  <ThTablePrices>Preço Total</ThTablePrices>
                </TrTable>
              </TableHead>

              <TableBody>
                {orderList.map((itemsCart, index) => {
                  return (
                    <TrTable key={index}>
                      <TdTable>{index + 1}</TdTable>
                      <TdTable>{itemsCart.codProd}</TdTable>
                      <TdTableDesc>{itemsCart.nameProduct}</TdTableDesc>
                      <TdTable>{itemsCart.qtd}</TdTable>
                      <TdTablePrices>
                        <PricesFormat
                          placeholder=""
                          displayType="text"
                          value={itemsCart.priceUnit || 0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </TdTablePrices>
                      <TdTablePrices>
                        <PricesFormat
                          placeholder=""
                          displayType="text"
                          value={itemsCart.valueTotalItem || 0}
                          decimalSeparator=","
                          thousandSeparator="."
                          fixedDecimalScale
                          decimalScale={2}
                          prefix={"R$ "}
                        />
                      </TdTablePrices>
                    </TrTable>
                  );
                })}
              </TableBody>
            </Table>
            <DivDelivery>
              <DivOrgTitleDelivery>
                <TitleDelivery>Entrega</TitleDelivery>
              </DivOrgTitleDelivery>
              <InfoDelivery>
                Rua: {infoClient?.street} N: {infoClient?.number}
              </InfoDelivery>
              <InfoDelivery>
                Bairro: {infoClient?.district} Cidade: {infoClient?.city}/
                {infoClient?.state}
              </InfoDelivery>
              <InfoDelivery>Complemento: {infoClient?.complement}</InfoDelivery>
            </DivDelivery>

            <TablePayments>
              <TableFoot>
                <TrPayments>
                  <TdPayments>Forma de pagamento</TdPayments>
                  <TdValues>{parseName(infoFinishOrder?.formPayment)}</TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Valor do Frete</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueDelivery}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Total do pedido</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueNoDiscount}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Desconto</TdPayments>
                  <TdValues>
                    <PricesFormat 
                      displayType="text"
                      value={infoFinishOrder?.valueDiscount}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Valor Final</TdPayments>
                  <TdValues>
                    <PricesFormat 
                      displayType="text"
                      value={infoFinishOrder?.valueWithDiscount}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Valor Pago</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueClientPayed}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
                <TrPayments>
                  <TdPayments>Troco</TdPayments>
                  <TdValues>
                    <PricesFormat
                      displayType="text"
                      value={infoFinishOrder?.valueChange}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"R$ "}
                    />
                  </TdValues>
                </TrPayments>
              </TableFoot>
            </TablePayments>
          </DivOrgTable>
        </DivOrgInfoAnimation>

        <DivOrgBtnOrder>
          <DivOrgSelect>
            <SelectPaper
              defaultValue={0}
              onChange={(e) => setTypePaper(e.target.value)}
            >
              <OptionsPaper value={true}>A4</OptionsPaper>
              <OptionsPaper value={false}>Cupom</OptionsPaper>
            </SelectPaper>
          </DivOrgSelect>
          <BtnNewOrder type="button" to="/comercial">
            Novo Pedido
          </BtnNewOrder>
          <BtnPrint type="button" onClick={printOrder}>
            Imprimir Comprovante
          </BtnPrint>
          {/* <BtnMail>Enviar por E-mail</BtnMail> */}
        </DivOrgBtnOrder>
      </DivOrgScreen>
    </DivOrderAnimation>
  );
}
