import styled from "styled-components";
import { Colors } from "../../../variable";

export const DivOrgResumeClientPayment = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 25px;
  margin: 5px auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  animation: grow 0.1s ease-in;
  @keyframes grow {
    0% {
      height: 10px;
    }
    50% {
      height: 30px;
    }
    100% {
      height: 60px;
    }
  }
`;

export const DivInfoResumeClientPayment = styled.div`
  width: 90%;
  padding: 10px;
  display: flex;
  margin: 5px auto;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${Colors.BackgroundColors.BkCards.White};
  border-radius: 25px;
`;

export const DivOrgPrices = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 320px) and (max-width: 940px) {
    width: 100%;

  }
`;

export const DivOrgInfo = styled.div`
  width: 100%;
  height: 25%;
  margin: 1.5px;
  border-radius: 25px;
  padding: 0 5px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
`;
export const DivBtnClose = styled.div`
  width: 100%;
  height: 20%;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
`;

export const BtnClose = styled.button`
  width: 5%;
  background: ${Colors.ButtonsColors.Canceled};
  border-radius: 100%;
  border: 1px solid black;
  cursor: pointer;

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 3px ${Colors.ButtonsColors.ShadowButton};
    color: ${Colors.Text.Black};
  }
`;

export const InfoResumeClientPaymentResult = styled.p`
  font-size: 0.9em;
`;

export const DivOrgTitle = styled.div`
  width: 100%;
  height: 15%;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TitleInfoOrder = styled.h4``;
